import Cookies from 'js-cookie'

const host = process.env.BACKEND_HOST
const publisherUuid = process.env.PUBLISHER_UUID
const authCookieName = 'dirauth'
const contractCodeLocalStorageName = 'contractCode'
const userLocalStorageName = 'currentUser'

export const login = ({ email, password }) => {
  return window
    .fetch(`${host}/connect/v1/publisher/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
        publisherUuid,
      }),
    })
    .then((response) => response.json())
    .then((json) => {
      const token = json.data.token
      if (token) {
        Cookies.set(authCookieName, token, { expires: 365 })
      }
      return !!token
    })
    .catch((error) => {
      return false
    })
}

export const loginAs = (token) => {
  Cookies.set(authCookieName, token, { expires: 365 })
}

export const logout = () => {
  Cookies.remove(authCookieName)
  localStorage.removeItem(userLocalStorageName)
}

export const checkLogin = () => {
  const token = Cookies.get(authCookieName)
  return !!token
}

export const authToken = () => {
  return Cookies.get(authCookieName)
}

export const setCurrentUser = (user) => {
  localStorage.setItem(userLocalStorageName, JSON.stringify(user))
}

export const getCurrentUser = () =>
  JSON.parse(localStorage.getItem(userLocalStorageName)) || {}

export const setContractCode = (contractCode) =>
  localStorage.setItem(contractCodeLocalStorageName, contractCode)

export const getContractCode = () =>
  localStorage.getItem(contractCodeLocalStorageName)
